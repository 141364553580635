import React, { Fragment } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import MediaQuery from 'react-responsive'
import ClubLayout from './layout'
import {
  searchMemberInClub,
  createUserProfileInfo,
  listMemberInClub,
  listDataPersonalInfo,
  getProfileFromId,
  getPositionFromId,
  postJoinClub,
  postUpdateProfileInfo,
  deleteUserInfo,
  postInviteClub,
  reinviteClubMember,
} from '../../utils/api'
import ReactPaginate from 'react-paginate'
import Loading from '../loading'
import _ from 'lodash'
import { navigate } from 'gatsby'
import MemberDetail from './components/member-detail'
import AddMember from './components/add-member'
import EditMember from './components/edit-member'
import DeleteMember from './components/delete-member'
import IconDown from '../../images/ic_down.svg'
import IconPlus from '../../images/club-event/plus.png'
import IconTrash from '../../images/icons/ic_trash.png'
import IconReinvite from '../../images/icons/icon_reinvite.png'
import IconOther from '../../images/icons/ic_other.png'
import PopupAction from '../club/components/popupaction-modal'
import PopUpInformationModal from '../club/components/popupInformation-modal'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const BoxInputAndAddMember = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 48px;
  .box-left {
    flex: 1;
  }
  .box-right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`
const ButtonAddMember = styled.button`
  position: relative;
  font-family: RSU;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-align: center;
  line-height: 28px;
  padding: 10px 41px;
  background: #892a24;
  border-radius: 8px;
`
const SelectBox = styled.select`
  max-width: 250px;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  padding: 2px 26px 3px 10px;
  border-radius: 4px 0 0 4px !important;
  line-height: 35px;
  background: #fff url(${IconDown}) no-repeat right;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  background-position-x: 90%;
`
const SelectBoxMobile = styled.select`
  width: 100%;
  font-size: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  padding: 2px 26px 3px 10px;
  border-radius: 4px 0 0 4px !important;
  line-height: 35px;
  background: #fff url(${IconDown}) no-repeat right;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.08);
  -webkit-appearance: none;
  background-position-x: 90%;
`

const InputSearchContainer = styled.div`
  position: relative;
  width: 250px;
  font-size: 16px;

  & i {
    font-size: 12px;
    position: absolute;
    top: 13px;
    left: 13px;
  }
  ${media.lessThan('medium')`
    width: 100%;
  `}
`

const InputSearch = styled.input`
  background: #ffffff;
  /* border-left: 1px solid #f0f0f1; */
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 0 4px 4px 0 !important;
  height: 40px;
  width: 100%;
  padding-left: 32px;
  padding-right: 16px;
`

const ColumnHeader = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
  flex: 1;
`
const ColumnHeaderAction = styled.div`
  opacity: 0.87;
  font-family: RSU-Bold;
  font-size: 24px;
  color: #3f3131;
  flex: 1;
  text-align: center;
`
const Line = styled.hr`
  border: 0;
  height: 1px;
  background: #f0f0f1;
  margin: 20px 0;
`
const ColumnValue = styled(ColumnHeader)`
  font-family: RSU;
  z-index: 1;
`
const ColumnAction = styled(ColumnHeader)`
  font-family: RSU;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  div {
    margin-right: 28%;
  }
  .assign,
  .edit,
  .delete {
    opacity: 0.87;
    font-family: RSU;
    font-size: 16px;
    color: #3f3131;
    border-bottom: 1px solid #3f3131;
  }
  .delete {
    color: #892a24;
  }
  .box-menu-mobile {
    margin-right: 0;
    position: relative;
    img {
      z-index: 1;
    }
    .sub-menu {
      z-index: 2;
      position: absolute;
      left: -170px;
      top: 0;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.08);
      border-radius: 0 0 4px 4px;
      span {
        white-space: nowrap;
        font-family: RSU;
        font-size: 16px;
        color: #3f3131;
        letter-spacing: 0.5px;
        text-align: left;
        line-height: 28px;
        padding: 6px 24px;
      }
      .delete {
        color: #892a24;
        border-bottom: none;
      }
    }
  }
`
const ColumnSubValue = styled.div`
  opacity: 0.34;
  font-size: 16px;
  color: #3f3131;
  letter-spacing: 0.5px;
  text-align: left;
  line-height: 28px;
`
const profilePicture = {
  width: "90px",
  height: "90px",
  borderRadius: "50%",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  border: "2px solid #892A24",
  marginTop: "2%"
}
const profilePictureMobile = {
  marginLeft: "40%",
  width: "90px",
  height: "90px",
  borderRadius: "50%",
  backgroundPosition: "center center",
  backgroundSize: "cover",
  border: "2px solid #892A24",
  marginTop: "2%"
}
const memberName = { paddingTop: "4%", paddingBottom: "3%" }
const memberNameMobile = {
  paddingTop: "4%",
  paddingBottom: "3%",
  fontSize: "19px",
}
const memberDetail = {
  opacity: 0.34,
  fontSize: "16px",
  color: "#3f3131",
  letterSpacing: "0.5px",
  textAlign: "left",
  lineHeight: "28px",
}

/* eslint-disable jsx-a11y/anchor-is-valid */
export default class Club extends React.Component {
  static defaultProps = {
    hideBG: true,
    underlineColor: '#C14C40',
  }

  constructor(props) {
    super(props)
    this.state = {
      style: null,
      memberList: [
        // {
        //   username: 'eeace068-9200-45c6-81c2-0d90533df71a',
        //   club_id: '5de91157b197f8af6e15e80c',
        //   created_at: '2019-12-07T19:48:46.823Z',
        //   firstname: 'Phongpat',
        //   lastname: 'Namjaingom',
        //   class_of: 'Class of 38',
        //   student_id: '123456',
        //   position: 'Owner',
        //   is_active: true,
        // },
      ],
      search: '',
      offset: 0,
      total: 0,
      loading: false,
      list: null,
      selectedMember: null,
      selectFilter: 'all',
      selectMajor: 0,
      openModal: false,
      openModalAddMember: false,
      openModalEditMember: {
        visible: false,
        profileId: '',
        firstname: '',
        lastname: '',
        student_id: '',
        user_info: {},
      },
      openModalDeleteMember: {
        visible: false,
        profileId: '',
      },
      isPermission: '',
      visibleActionMobile: { visible: false, profileId: '' },
      openActionModal: false,
      actionDisplayMessage: '',
      reInviteProfileID: '',
      reInviteInfo: {
        clubID: '',
        payload: [{
          //  name: 'first+lastname',
          //  email: 'email'
          //  lang: 'lang'
        }],
      },
      informationOnly: false,
      openInformationModal: false,
      memberReinviteResult: 'string',
      informationType: '',
      activateMajorBox: false,
    }
    this.debounceDelay = _.debounce(this.debounceDelay.bind(this), 1000)
    this.debounceDelay2 = _.debounce(this.debounceDelay2.bind(this), 300)
    this.handleReinviteMember = this.handleReinviteMember.bind(this)
    this.handlepopUpAction = this.handlepopUpAction.bind(this)
    this.handleReinviteMember = this.handleReinviteMember.bind(this)
    this.handlepopUpInformation = this.handlepopUpInformation.bind(this)
  }

  async componentDidMount() {
    let uid = null
    // if (this.props.authState && this.props.authState.currentUser) {
    //   uid = this.props.authState.currentUser.username
    // } else if (this.props.cookieAuth) {
    console.log('this.props.cookieAuth', this.props.cookieAuth)
    uid = this.props.cookieAuth
    // }
    if (uid) {
      const userData = await getProfileFromId(uid)
      if (!userData.data) {
        navigate('/club/KMITLEngineerAlumni')
      }
    }
    if (uid) {
      const positionData = await getPositionFromId(uid)
      if (positionData && positionData.data) {
        this.setState({ isPermission: positionData.data.position || '' })
      }
    }
    const w = document.documentElement.clientWidth
    const bgColor = this.props.hideBG ? null : { backgroundColor: '#E8E8E8' }
    if (w <= 450) {
      this.setState({ style: { padding: '20px 10px', ...bgColor } })
    } else {
      this.setState({ style: { padding: '20px 0', ...bgColor } })
    }
    const list = await listDataPersonalInfo()
    this.setState({ list: list.data })
    // { Remove Member display autometic after visit this page
    // if (this.props.auth) {
    //   const data = await listMemberInClub()
    //   if (data.data && data.data.members) {
    //     this.setState({ memberList: data.data.members, total: data.data.total })
    //   } else {
    //     this.setState({ memberList: [...this.state.memberList] })
    //   }
    // }
    // Remove Member display autometic after visit this page }
  }

  debounceDelay = value => {
    this.searchAuth(value)
  }

  debounceDelay2 = value => {
    this.getListMemberInClub()
  }

  getListMemberInClub = async () => {
    const data = await listMemberInClub()
    if (data.data) {
      this.setState({
        memberList: data.data.members,
        total: data.data.total,
        loading: false,
      })
    }
  }

  searchAuth = async (search = '', skip = 0) => {
    const data = await searchMemberInClub(search, skip, this.state.selectFilter)
    console.log(data.data)
    if (this.state.search) {
      this.setState({
        memberList: data.data.members || [],
        total: data.data.total,
        loading: false,
      })
    } else {
      if (this.props.auth) {
        this.setState({
          memberList: [...this.state.memberList],
          loading: false,
        })
      } else {
        if (this.props.auth) {
          this.setState({
            memberList: [...this.state.memberList],
            loading: false,
          })
        } else {
          this.setState({ memberList: [], loading: false })
        }
      }
    }
  }

  styleFont1 = { fontFamily: `'Noto Serif', serif` }

  handleTabs = tab => {
    this.setState({ tab })
  }
  handleAddMember = async payload => {
    const create = await createUserProfileInfo({
      ...payload,
      user_of: this.props.clubRegister.user_of,
    })
    if (create.code === 'OK') {
      const update_member = await postJoinClub(
        {
          position: 'Member',
        },
        { 'X-Profile-ID': create.data._id }
      )
    }
    await this.handleModalAddMember()
    return
  }
  handleOnChange = (e, key) => {
    console.log(key, e.target.value)
    this.setState({ [key]: e.target.value })
  }
  handleModalAddMember = () => {
    this.setState({ openModalAddMember: !this.state.openModalAddMember })
  }
  handlepopUpAction = () => {
    this.setState({ openActionModal: !this.state.openActionModal })
  }
  handlepopUpInformation = () => {
    this.setState({ openInformationModal: !this.state.openInformationModal })
  }
  handleChangeSelect = e => {
    this.setState({ selectFilter: e.target.value })
    if (e.target.value == 'major') {
      this.setState({ activateMajorBox: true })
    } else {
      this.setState({ activateMajorBox: false })
    }
  }
  handleSelectMajor = e => {
    const majorIndex = e.target.value
    this.debounceDelay(majorIndex)
    this.setState({
      search: majorIndex,
      offset: 0,
      total: 0,
      loading: true,
      selectMajor: majorIndex,
    })
  }
  handlePermissionUser = async (type, id) => {
    const dataJoinClub = await postJoinClub(
      {
        position: type === 'add' ? 'Admin' : 'Member',
        is_active: true,
      },
      { 'X-Profile-ID': id }
    )
    if (dataJoinClub) {
      this.setState({
        memberList: this.state.memberList.map(item => {
          if (item.profile_id === id) {
            item.position = type === 'add' ? 'Admin' : 'Member'
          }
          return item
        }),
        visibleActionMobile: { visible: false, profileId: '' },
      })
    }
  }
  handleEditUserInfo = async (payload, id) => {
    const updateUserInfo = await postUpdateProfileInfo(payload, {
      'X-Profile-ID': id,
    })
    if (updateUserInfo) {
      this.setState({
        memberList: this.state.memberList.map(item => {
          if (item.profile_id === id) {
            item.profile.user_info.firstname = payload.user_info.firstname
            item.profile.user_info.lastname = payload.user_info.lastname
            item.profile.user_info.student_id = payload.user_info.student_id
          }
          return item
        }),
      })
      this.closeModalEditMember()
    }
  }
  closeModalEditMember = () => {
    this.setState({
      openModalEditMember: {
        visible: false,
        profileId: '',
        firstname: '',
        lastname: '',
        student_id: '',
        user_info: {},
      },
    })
  }
  addSetOpenEdit = ({
    firstname,
    lastname,
    student_id,
    profileId,
    user_info,
  }) => {
    this.setState({
      openModalEditMember: {
        visible: true,
        firstname,
        lastname,
        student_id,
        profileId,
        user_info: user_info,
      },
      visibleActionMobile: { visible: false, profileId: '' },
    })
  }
  addSetOpenDelete = profileId => {
    this.setState({
      openModalDeleteMember: { visible: true, profileId },
      visibleActionMobile: { visible: false, profileId: '' },
    })
  }
  clearDelete = () => {
    this.setState({ openModalDeleteMember: { visible: false, profileId: '' } })
  }
  // reinvite clubmember in case of invite link expried
  handleReinviteMember = async (deleteProfileID, reInviteInfo) => {
    const deleteuser = await deleteUserInfo({ 'X-Profile-ID': deleteProfileID, })
    if (deleteuser.code == 'OK') {
      const sendReinvite = await reinviteClubMember(reInviteInfo.clubID, JSON.stringify(reInviteInfo.payload), {
        'X-Profile-ID': cookies.get('_id'),
        'Content-Type': 'application/json',
      })
      if (sendReinvite.code == 'OK') {
        this.setState({ informationOnly: false, memberReinviteResult: 'REINVITE_SUCCESS', informationType: 'positive' }, () => {
          this.handlepopUpInformation()
        })
      }
    } else { console.log('Fail') }
  }
  handleDeleteUserInfo = async profileId => {
    const deleteUser = await deleteUserInfo({
      'X-Profile-ID': profileId,
    })
    if (deleteUser) {
      this.setState({
        memberList: this.state.memberList.filter(
          item => item.profile_id !== profileId
        ),
      })
      this.clearDelete()
    }
  }
  handleRenderAction = (position, positionUser, profileId, userInfo) => {
    const { firstname, lastname, student_id, email } = userInfo
    let lowerPosition = (position && position.toLowerCase()) || 'member'
    let lowerPositionUser =
      (positionUser && positionUser.toLowerCase()) || 'member'

    if (lowerPositionUser === 'owner') return null

    return (
      <>
        <MediaQuery minWidth={769}>
          {(lowerPosition === 'owner' && (
            <div
              style={{ marginRight: '14%' }}
              className={lowerPositionUser === 'admin' ? 'delete' : 'assign'}
              onClick={() =>
                this.handlePermissionUser(
                  lowerPositionUser === 'admin' ? 'delete' : 'add',
                  profileId
                )
              }
            >
              {lowerPositionUser === 'admin'
                ? this.props.locale['REMOVE_ADMIN']
                : this.props.locale['ASSIGN_ADMIN']}
            </div>
          )) ||
            null}
          <div style={position.toLowerCase() === 'owner' ? { marginRight: '14%' } : null}
            onClick={() => {
              this.handlepopUpAction()
              this.setState({
                actionDisplayMessage: 'REINVITE_CLUB_MEMBER',
                reInviteProfileID: profileId,
                reInviteInfo: {
                  clubID: this.props.clubID,
                  payload: [{
                    name: `${firstname} ${lastname}`,
                    email: email,
                    lang: this.props.lang
                  }]
                },
              })
            }}
          >
            <img style={{ marginTop: "3px" }} src={IconReinvite} alt="edit" />
          </div>
          <div
            style={position.toLowerCase() === 'owner' ? { marginRight: '14%' } : null}
            onClick={() => this.addSetOpenDelete(profileId)}>
            <img src={IconTrash} alt="trash" />
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={768}>
          <div className="box-menu-mobile">
            <img
              src={IconOther}
              alt="other"
              onClick={() =>
                this.setState({
                  visibleActionMobile: { visible: true, profileId: profileId },
                })
              }
            />
            {(this.state.visibleActionMobile.visible &&
              this.state.visibleActionMobile.profileId === profileId && (
                <div className="sub-menu">
                  {(lowerPosition === 'owner' && (
                    <span
                      className={lowerPositionUser === 'admin' ? 'delete' : ''}
                      onClick={() =>
                        this.handlePermissionUser(
                          lowerPositionUser === 'admin' ? 'delete' : 'add',
                          profileId
                        )
                      }
                    >
                      {lowerPositionUser === 'admin'
                        ? this.props.locale['REMOVE_ADMIN']
                        : this.props.locale['ASSIGN_ADMIN']}
                    </span>
                  )) ||
                    null}

                  <span
                    onClick={() => {
                      this.handlepopUpAction()
                      this.setState({
                        actionDisplayMessage: 'REINVITE_CLUB_MEMBER',
                        reInviteProfileID: profileId,
                        reInviteInfo: {
                          clubID: this.props.clubID,
                          payload: [{
                            name: `${firstname} ${lastname}`,
                            email: email,
                            lang: this.props.lang
                          }]
                        },
                      })
                    }}
                  >
                    Reinvite
                  </span>
                  <span onClick={() => this.addSetOpenDelete(profileId)}>
                    Delete
                  </span>
                </div>
              )) ||
              null}
          </div>
        </MediaQuery>
      </>
    )
  }
  display(props) {
    const { memberList, total } = this.state
    const { auth } = this.props
    return (
      <ClubLayout {...props}>
        <div>
          {/* {!auth && (
            <p>
              If you would like to see the list of others in your club, Please{' '}
              <span
                className="information__link"
                onClick={() => navigate('/club/KMITLEngineerAlumni/')}
              >
                {' '}
                Sign in
              </span>{' '}
              to be an Invitree account.
            </p>
          )} */}
          {/* if login and have member */}
          <Fragment>
            <div>
              <BoxInputAndAddMember>
                <div className="box-left">
                  <MediaQuery maxWidth={768}>
                    <SelectBox
                      name="select_field"
                      width={'100px'}
                      onChange={this.handleChangeSelect}
                      value={this.state.selectFilter}
                    >
                      <option value="all">
                        {this.props.locale['LIST_ALL']}
                      </option>
                      <option value="firstname">
                        {this.props.locale['LIST_NAME']}
                      </option>
                      <option value="lastname">
                        {this.props.locale['LIST_LAST_NAME']}
                      </option>
                      <option value="nickname1,nickname2">
                        {this.props.locale['LIST_NICKNAME']}
                      </option>
                      <option value="class_year">
                        {this.props.locale['LIST_CLASS_YEAR']}
                      </option>
                      <option value="class_of">
                        {this.props.locale['LIST_CLASS_OF']}
                      </option>
                      <option value="major">
                        {this.props.locale['MAJOR']}
                      </option>
                    </SelectBox>
                  </MediaQuery>
                </div>
                <div className="box-right">
                  <div>
                    <MediaQuery minWidth={769}>
                      <SelectBox
                        name="select_field"
                        width={'100px'}
                        onChange={this.handleChangeSelect}
                        value={this.state.selectFilter}
                      >
                        <option value="all">
                          {this.props.locale['LIST_ALL']}
                        </option>
                        <option value="firstname">
                          {this.props.locale['LIST_NAME']}
                        </option>
                        <option value="lastname">
                          {this.props.locale['LIST_LAST_NAME']}
                        </option>
                        <option value="nickname1,nickname2">
                          {this.props.locale['LIST_NICKNAME']}
                        </option>
                        <option value="class_year">
                          {this.props.locale['LIST_CLASS_YEAR']}
                        </option>
                        <option value="class_of">
                          {this.props.locale['LIST_CLASS_OF']}
                        </option>
                        <option value="major">
                          {this.props.locale['MAJOR']}
                        </option>
                      </SelectBox>
                    </MediaQuery>
                  </div>
                  {/* Select major section */}
                  {this.state.activateMajorBox ?
                    (
                      <div>
                        <MediaQuery minWidth={769}>
                          <SelectBox
                            id="select_major"
                            name="select_major"
                            width={'100px'}
                            onChange={this.handleSelectMajor}
                            value={this.state.selectMajor}
                          >
                            <option value={0} disabled>
                              {this.props.locale['SELECT_MAJOR']}
                            </option>
                            {
                              this.state.list.major[`${this.props.lang}`.toLowerCase()].map(({ id, value }) => {
                                return (
                                  <option value={id}>
                                    {value}
                                  </option>
                                )
                              })
                            }
                          </SelectBox>
                        </MediaQuery>
                        <MediaQuery maxWidth={768}>
                          <SelectBoxMobile
                            id="select_major"
                            name="select_major"
                            width={'100px'}
                            onChange={this.handleSelectMajor}
                            value={this.state.selectMajor}
                          >
                            <option value={0}>
                              {this.props.locale['SELECT_MAJOR']}
                            </option>
                            {
                              this.state.list.major[`${this.props.lang}`.toLowerCase()].map(({ id, value }) => {
                                return (
                                  <option value={id}>
                                    {value}
                                  </option>
                                )
                              })
                            }
                          </SelectBoxMobile>
                        </MediaQuery>
                      </div>
                    ) :
                    (<InputSearchContainer>
                      <i className="fas fa-search" />
                      <InputSearch
                        placeholder="Search"
                        onChange={e => {
                          const val = e.target.value.trim()
                          if (val === '') {
                            if (this.props.auth) {
                              this.debounceDelay2()
                              this.setState({
                                search: val,
                                offset: 0,
                                total: 0,
                                loading: true,
                              })
                            } else {
                              this.setState({
                                memberList: [],
                                search: '',
                                loading: false,
                              })
                            }
                          } else {
                            this.debounceDelay(val)
                            this.setState({
                              search: val,
                              offset: 0,
                              total: 0,
                              loading: true,
                            })
                          }
                        }}
                      />
                    </InputSearchContainer>)
                  }
                </div>
              </BoxInputAndAddMember>
              <div>
                <div className="flex align-center">
                  <ColumnHeader>{props.locale['MEMBER_NAME']}</ColumnHeader>
                  {/* Blank column improve page layout */}
                  <ColumnHeader></ColumnHeader>
                  <MediaQuery minWidth={768}>
                    {((this.state.isPermission == 'Admin' ||
                      this.state.isPermission == 'Owner') && (
                        <ColumnHeaderAction>
                          {props.locale['TABLE_MENU_ACTION']}
                        </ColumnHeaderAction>
                      )) ||
                      null}
                  </MediaQuery>
                </div>
              </div>
              <Line />

              {memberList.map(({ profile, profile_id, position }, i, arr) => {
                const { firstname, lastname, major, nickname1, nickname2, profile_img_url } = profile.user_info
                let indexInMajor = parseInt(major) - 1
                let majorName = ""
                if (isNaN(major)) { indexInMajor = 34 }
                if (this.props.lang == 'EN') {
                  majorName = this.state.list.major.en[indexInMajor].value
                } else {
                  majorName = this.state.list.major.th[indexInMajor].value
                }
                return (
                  <div key={i} style={{ cursor: 'pointer' }}>
                    <div className="flex">
                      <MediaQuery minWidth={768}>
                        <ColumnValue
                          onClick={e => {
                            this.setState({
                              selectedMember: profile,
                              openModal: true,
                            })
                          }}
                        >
                          <div>
                            <div style={memberName}>
                              {firstname} {lastname}
                            </div>
                            <div style={memberDetail}>
                              {nickname1 ? (`${nickname1}`) : null}
                              {nickname2 ? (`/${nickname2}`) : null}
                              <br />
                              {indexInMajor == 34 ? null : `${majorName}`}
                            </div>
                          </div>
                        </ColumnValue>
                      </MediaQuery>
                      <MediaQuery minWidth={768}>
                        <ColumnValue
                          onClick={e => {
                            this.setState({
                              selectedMember: profile,
                              openModal: true,
                            })
                          }}
                        >
                          <div>
                            {profile_img_url ? (<img style={profilePicture} src={profile_img_url} />) : null}
                          </div>
                        </ColumnValue>
                      </MediaQuery>
                      <MediaQuery maxWidth={767}>
                        <ColumnValue
                          onClick={e => {
                            this.setState({
                              selectedMember: profile,
                              openModal: true,
                            })
                          }}
                        >
                          <div>
                            <div style={memberNameMobile}>
                              {firstname}
                              <br />
                              {lastname}
                            </div>
                            <div style={memberDetail}>
                              {nickname1 ? (`${nickname1}`) : null}
                              {nickname2 ? (`/${nickname2}`) : null}
                              <br />
                              {indexInMajor == 34 ? null : `${majorName}`}
                            </div>
                          </div>
                        </ColumnValue>
                      </MediaQuery>
                      <MediaQuery maxWidth={767}>
                        <ColumnValue
                          onClick={e => {
                            this.setState({
                              selectedMember: profile,
                              openModal: true,
                            })
                          }}
                        >
                          <div>
                            {profile_img_url ? (<img style={profilePictureMobile} src={profile_img_url} />) : null}
                          </div>
                        </ColumnValue>
                      </MediaQuery>
                      {(this.state.isPermission == 'Admin' ||
                        this.state.isPermission == 'Owner') && (
                          <ColumnAction>
                            {this.handleRenderAction(
                              this.state.isPermission,
                              position,
                              profile_id,
                              profile.user_info
                            )}
                          </ColumnAction>
                        )}
                    </div>
                    {arr.length - 1 !== i && <Line />}
                  </div>
                )
              })}
            </div>
            {this.state.memberList.length > 0 && (
              <div style={{ textAlign: 'right' }}>
                {/* <Pagination
                    // total={this.state.memberList.length}
                    total={total}
                    limit={10}
                    offset={this.state.offset}
                    // onChange={props.onChangePagination}
                    onChange={offset => {
                      if (this.props.auth) {
                        this.searchAuth(this.state.search, offset)
                      } else {
                        this.searchNoAuth(this.state.search, offset)
                      }
                      this.setState({ offset })
                    }}
                  /> */}
                <ReactPaginate
                  containerClassName="page-container"
                  previousLabel={'«'}
                  nextLabel={'»'}
                  previousClassName="page-link"
                  nextLinkClassName="page-link"
                  previousLinkClassName="color-red"
                  nextLinkClassName="color-red page-link"
                  pageLinkClassName="page-link"
                  activeClassName="page-link-active"
                  activeLinkClassName={'page-link-active-link'}
                  // breakLabel={<span className="gap">...</span>}
                  pageCount={total / 10}
                  onPageChange={async ({ selected }) => {
                    this.setState({ loading: true })
                    console.log('nextPage:', selected)
                    const offset = selected * 10
                    console.log('offset:', offset)
                    if (this.state.search) {
                      if (this.props.auth) {
                        this.searchAuth(this.state.search, offset)
                      }
                      // remove search no auth
                      // else {
                      //   this.searchNoAuth(this.state.search, offset)
                      // }
                    } else {
                      const data = await listMemberInClub(offset)
                      if (data.data && data.data.members) {
                        this.setState({
                          memberList: data.data.members,
                          total: data.data.total,
                          loading: false,
                        })
                      } else {
                        this.setState({
                          memberList: [...this.state.memberList],
                          loading: false,
                        })
                      }
                    }
                    this.setState({ offset })
                    window.scrollTo(0, 0)
                  }}
                  forcePage={this.state.offset / 10}
                />
              </div>
            )}
          </Fragment>
          {this.state.loading && <Loading />}
        </div>
        <AddMember
          isOpen={this.state.openModalAddMember}
          locale={this.props.locale}
          handleAddMember={this.handleAddMember}
          handleModalAddMember={this.handleModalAddMember}
        />
        <EditMember
          state={this.state.openModalEditMember}
          closeModalEditMember={this.closeModalEditMember}
          handleEditUserInfo={this.handleEditUserInfo}
          locale={this.props.locale}
        />
        <DeleteMember
          clearDelete={this.clearDelete}
          handleDeleteUserInfo={this.handleDeleteUserInfo}
          state={this.state.openModalDeleteMember}
          locale={this.props.locale}
        />
        <PopupAction
          isOpen={this.state.openActionModal}
          locale={this.props.locale}
          handleActionTask={this.handleReinviteMember}
          handlepopUpAction={this.handlepopUpAction}
          actionDisplayMessage={this.state.actionDisplayMessage}
          ActionTaskInfo1={this.state.reInviteProfileID}
          ActionTaskInfo2={this.state.reInviteInfo}
        />
        <PopUpInformationModal
          isOpen={this.state.openInformationModal}
          handlepopUpInformation={this.handlepopUpInformation}
          locale={this.props.locale}
          resultToDisplay={this.state.memberReinviteResult}
          informationType={this.state.informationType}
          informationOnly={this.state.informationOnly}
        />
        {this.state.selectedMember && (
          <MemberDetail
            locale={this.props.locale}
            openModal={this.state.openModal}
            closeModal={() => this.setState({ openModal: false })}
            detail={this.state.selectedMember}
            list={this.state.list}
            lang={this.props.lang.toLowerCase()}
          />
        )}
      </ClubLayout>
    )
  }

  render() {
    return this.display(this.props)
  }
}
