import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import { getProfileFromId, getProfile } from '../../utils/api'
import moment from 'moment'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Loading from '../../components/loading'
import View from '../../components/presentation/my-club'
import { BoxGray } from '../../components/template/box'
import authActions from '../../state/auth/actions'
import homeActions from '../../state/home/actions'
import Cookies from 'universal-cookie'
import clubRegisterActions from '../../state/club-register/actions'

const cookies = new Cookies()

class MyClubPage extends React.Component {
  componentDidMount() {
    this.props.checkAuthorization(null, '/KMITLEngineerAlumni', null, {
      onSuccess: () => this.props.onLoad(),
    })
    this.handleCheckProfile()
  }
  handleCheckProfile = async () => {
    const resData = await getProfile()
      if (resData.data && resData.data.username) {
        const userData = await getProfileFromId(resData.data.username)
        if (userData && userData.data && userData.data._id) {
          cookies.set('_id', userData.data._id, {
            path: '/',
            expires: new Date(
              moment()
                .add(1, 'day')
                .format()
            ),
          })
          this.props.onSetProfile({
            ...userData.data,
          })
        } else {
          navigate(`/home`)
        }
      }
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    if (!this.props.state.readyPageMyEvent) {
      return <Loading />
    }

    if (this.props.auth.currentUser !== null) {
      return (
        <Layout auth={this.props.auth.currentUser !== null}
                clubRegister={this.props.clubRegister}>
          <div>
            <SEO keywords={[`invitree`]} />
            <BoxGray>
              <View
                locale={this.props.app.locale[this.props.app.lang]}
                location={this.props.location}
                auth={this.props.auth.currentUser !== null}
                authState={this.props.auth}
                clubRegister={this.props.clubRegister}
                cookieAuth={cookies.get('_id')}
                lang={this.props.app.lang}
                clubID={'KMITLEngineerAlumni'}
              />
            </BoxGray>
          </div>
        </Layout>
      )
    }

    return (
      <div>
        <SEO keywords={[`invitree`]} />
        <BoxGray>
          <View
            locale={this.props.app.locale[this.props.app.lang]}
            location={this.props.location}
            auth={this.props.auth.currentUser !== null}
            authState={this.props.auth}
            clubRegister={this.props.clubRegister}
            cookieAuth={cookies.get('_id')}
            lang={this.props.app.lang}
            clubID={'KMITLEngineerAlumni'}
          />
        </BoxGray>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
    clubRegister: state.clubRegister,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onLoad: homeActions.onLoadMyEvent,
      onSetProfile: clubRegisterActions.onSetProfile,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyClubPage)
