import React, { useState } from 'react'
import Modal from 'react-modal'
import Loading from '../../loading'
import Button from '../../form/buttons'
import IconClose from '../../../images/ic_close.svg'
import IconWarning from '../../../images/icons/ic_warning.png'

const PopupAction = props => {
    const { isOpen, locale, handleActionTask, handlepopUpAction, actionDisplayMessage, ActionTaskInfo1, ActionTaskInfo2 } = props
    const [isLoading, setIsLoading] = useState(false)
    const setLoadingOn = () => setIsLoading(true)
    const setLoadingOff = () => setIsLoading(false)
    const styleBoxLoad = { position: 'relative', height: '80px', marginTop: '-40px' }
    async function onApproveAction(callback, ActionTaskInfo1, ActionTaskInfo2) {
        if (ActionTaskInfo1 && ActionTaskInfo2) {
            await handleActionTask(ActionTaskInfo1, ActionTaskInfo2)
            callback()
            handlepopUpAction()
            return
        }
        if (ActionTaskInfo1) {
            await handleActionTask(ActionTaskInfo1)
            callback()
            handlepopUpAction()
            return
        } else {
            await handleActionTask()
            callback()
            handlepopUpAction()
            return
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            className="information__modal"
            overlayClassName="information__modal__overlay"
        >
            <img
                src={IconClose}
                alt=""
                className="information__modal__close"
                onClick={handlepopUpAction}
            />
            <div className="content__modal">
                <div className="title__modal" style={{ marginTop: '40px' }}>
                    <img src={IconWarning} alt="warning" />
                </div>
                <div className="box__input__modal">
                    {locale[actionDisplayMessage]}
                </div>
            </div>
            <div className="flex">
                {isLoading === false ? (
                    <div>
                        <button className="popup-cancel-delete" onClick={handlepopUpAction}>
                            {locale['NO']}
                        </button>
                        <button className="popup-confirm-delete" onClick={() => {
                            setLoadingOn()
                            onApproveAction(setLoadingOff, ActionTaskInfo1, ActionTaskInfo2)
                        }}>
                            {locale['YES']}
                        </button>
                    </div>) : (
                        <div style={styleBoxLoad}>
                            <Loading />
                        </div>)
                }
            </div>
        </Modal>
    )

}

export default PopupAction